import React from "react";
import './App.scss';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Home from "./components/Home";

import PageSingle from "./views/pageSingle"
import PageAll from "./views/pageAll"

import TermsAndConditions from "./views/termsAndConditions"
import PrivacyPolicy from "./views/privacyPolicy"
import PageNotFound from "./views/pageNotFound"

import ReactGA from "react-ga4";

ReactGA.initialize("G-45GHHK8K91");
ReactGA.send("pageview");

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bits/:slug" element={<PageSingle />}/>
        <Route path="bits" element={<PageAll />}/>
        
        <Route path="terms" element={<TermsAndConditions />}/>
        <Route path="privacy" element={<PrivacyPolicy />}/>
        
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
  }

export default App;