import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

import { API_URL } from "../constants";

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);
  const navigate = useNavigate();
  const axiosInstance = axios.create({
    API_URL,
    headers: AuthHeader()
  });

  axiosInstance.interceptors.request.use(async req => {
    if (authTokens == null) return req;
    const user = jwt_decode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    const response = await axios.post(`${API_URL}account/token/refresh/`, {
      refresh: authTokens.refresh
    });

    localStorage.setItem("authTokens", JSON.stringify(response.data));

    setAuthTokens(response.data);
    setUser(jwt_decode(response.data.access));

    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
  });
  
  axiosInstance.interceptors.response.use(response => response, error => {
    if (error.response.status === 403){
      navigate("/account/login");
    }
  });

  return axiosInstance;
};

function AuthHeader() {
  const { authTokens } = useContext(AuthContext);
  
  if (authTokens != null) {
    return { Authorization: 'Bearer ' + authTokens.access };
  } else {
    return {};
  }
}

export default useAxios;

