import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import ReactPlayer from 'react-player/file'

import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { saveAs } from 'file-saver';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import axios from "axios";
import { API_URL } from "../constants";

function PageSingle(params) {
  const navigate = useNavigate();
  
  const [res, setRes] = useState("");
  const [comments, setComments] = useState(null);
  const [title, setTitle] = useState("");
  const [urlForLink, setUrlForLink] = useState("");
  const [loading, setLoading] = useState(true);
  const { slug } = useParams()
  
  
  useEffect(() => {
    document.title = "8-BitStudios"
    window.scrollTo(0, 0)
    
    setUrlForLink(window.location.href)
    
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL+"posts/"+slug+"/");
        setLoading(false);
        let data = response.data
        setRes(data);
        setTitle("8-BitStudios: "+data.title)
        setComments(data.comments)
      } catch {
        console.log("Problem")
        setLoading(true);
      }
    };
    fetchData();
  }, [slug]);
  
  const onHandleClick = (params) => (event) => {
    event.preventDefault();
    if (params) {
      navigate("/bits/" + params);
    }
  };
  
  // Main video/thumb block
  let blocky;
  if (res.type === "Youtube") {
    blocky = <YoutubeBlock link={res.youtube_embed_link} />;
  } else if (res.type === "Wallpaper" || res.object_type === "Image") {
    blocky = <WallpaperBlock res={res} />;
  }
  else if (res.type === "Video" || res.object_type === "Video") {
    blocky = <VideoBlock res={res} />;
  }
  
  // Info line, added
  let infoLine;
  if (res.created_at) {
    
    infoLine = "Added " + res.created_at + " | " + res.type + " | " + res.comments.length + " Comments";
  }  
  
  return (
    <>
  
    <section className="container py-4">
      <Nav />
    </section>
    
    <section className="py-4 mainHeader">
      <div className="container py-3">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="display-2">{res.title || <Skeleton/> }</h1>
          </div>
        </div>
      </div>
    </section>
    
    <div className="container py-4">
    
      <div className="col-lg-12 mb-5">
      {blocky || <Skeleton height={500}/> }
      </div>
      
      <div className="row">
        <div className="col-md-8">

          <article className="blog-post">
            <h2 className="blog-post-title">{res.title || <Skeleton /> }</h2>
            <p className="blog-post-meta">{res.created_at || <Skeleton/>}</p>
            <hr/>
        
            <DescBlock link={res.description}/>
          </article>

          <nav className="blog-pagination" aria-label="Pagination">
            <a onClick={onHandleClick(res.previous_post)} className="btn btn-outline-primary">Older</a>
            <a onClick={onHandleClick(res.next_post)} className={"btn btn-outline-primary " + (res.next_post ? '' : 'disabled')}>Newer</a>
          </nav>

        </div>

        <div className="col-md-4">
          <div className="position-sticky">
        
            <div className="p-4 mb-3 bg-light rounded">
              <h4 className="fst-italic">About Me</h4>
              <p className="mb-0">I make animations, memes and videos around a variety of games - Portal, Team Fortress, Minecraft etc. I also create wallpapers and designs, such as Titanfall, Metal Gear, Demon Slayer and others.</p>
            </div>
        
          </div>
        </div>
      </div>
      
    </div>

    <Footer />
    </>
  );
}

function YoutubeBlock(params){
  return (
    <>
    
    <div className="video-container">

      <div>
        <iframe
      src={"https://www.youtube.com/embed/"+params.link}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />{" "}
      </div>
        
    </div>
    </>
  )
}

function WallpaperBlock(params){
  
  // Download image
  const downloadWallpaper = (image) => (event) => {
    event.preventDefault();
    saveAs(image, '8bitstudios.jpg');
  };
  
  return (
    <>    
    <div className="video-container">

      <div className="mb-5">
        <img src={params.res.image_thumbnail} alt="8-Bit Wallpaper"/>
      </div>

    </div> 
    <div className="col-twelve tab-full">
    
      <div className="mb-1 mt-5">
        <a href="#" className="btn btn-primary btn-lg px-4"  onClick={downloadWallpaper(params.res.image)}>Download Full Resolution</a>
      </div>


    </div>
    </>
  )
}

function VideoBlock(params){
  return (
    <div className="video-container">

      <div>
        <ReactPlayer url={params.res.videofile} controls={true}/>
      </div>

    </div> 
  )
}

function DescBlock(params){
  if (params.link){
    return (
      <aside className="pull-quote">
        <blockquote>
          <p>{params.link}</p>
        </blockquote>
      </aside>
    )
  }
  
}

export default PageSingle;