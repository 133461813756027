import React, { Component } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

class Header extends Component {
  render() {
    return (
      
      <footer className="footer navbar-static-bottom">
        <div className="container">
          <a rel="noreferrer" href="#top" className="back-to-top">
            <i className="fa fa-angle-up fa-2x mb-3 p-2" aria-hidden="true"></i>
          </a>
          <div className="social-links">
            <a className="p-2" rel="noreferrer" href="https://displate.com/8bitstudios?art=62dc1893c7c12" target="_blank">
              <FontAwesomeIcon icon={faShoppingCart} size="lg"/>
            </a>
            <a className="p-2" rel="noreferrer" href="https://ko-fi.com/8_bitstudios" target="_blank" alt="Buy me a coffee!">
              <FontAwesomeIcon icon={faCoffee} size="lg"/>
            </a>
            <a className="p-2" rel="noreferrer" href="https://www.youtube.com/c/8BitStudiosAnimations" target="_blank">
              <FontAwesomeIcon icon={faYoutube} size="lg"/>
            </a>
          </div>

          <hr />

          <p className="footer__text">8-Bit Studios | <Link to="/terms">Terms and Conditions</Link> | <Link to="/privacy">Privacy Policy</Link></p>
          
        </div>
      </footer>
    );
  }
}

export default Header;