import { useEffect } from "react";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

function PageNotFound() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <>

      <section className="container py-4">
        <Nav />
      </section>

      <section className="py-4 mainHeader">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-10">
              <h1 className="display-2"><span className="text-danger">404 Page Not Found</span><br/>Sorry, this page does not exist!</h1>
            </div>
          </div>
        </div>
      </section>
              
      <Footer />
    
    </>
  );
}

export default PageNotFound;