import { useContext, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import {default as NavReact} from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';

const Nav = () => {
  const [isNavOpen, setNavOpen] = useState(false)
  
  useEffect(() => {
    document.body.classList.toggle('nav-wrap-is-visible', isNavOpen);
  },[isNavOpen])  
      
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand as={NavLink} to='/'>8-Bit Studios</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <NavReact className="me-auto">
          <NavReact.Link as={NavLink} to='/'>Home</NavReact.Link>
          <NavReact.Link as={NavLink} to='/bits'>Bits</NavReact.Link>
          <NavReact.Link rel="noopener noreferrer" href="https://www.youtube.com/c/8BitStudiosAnimations" target="_blank">YouTube</NavReact.Link>
          <NavDropdown title="Support Me" id="basic-nav-dropdown">
            <NavDropdown.Item rel="noopener noreferrer" href="https://displate.com/8bitstudios?art=62dc1893c7c12" target="_blank">Displate</NavDropdown.Item>
            <NavDropdown.Item rel="noopener noreferrer" href="https://ko-fi.com/8_bitstudios" target="_blank">Ko-Fi</NavDropdown.Item>
          </NavDropdown>
          <NavReact.Link as={NavLink} to='/#' onClick={(e) => {window.location.href ='mailto:hi@8bitanimations.com';}}>Contact</NavReact.Link>
        </NavReact>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Nav;