import { useEffect, useState} from "react";

// ReactJS Components
import PostList from "../views/postList";
import Footer from "./Footer";
import Nav from "./Nav";

// Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// Bootstrap
import Button from 'react-bootstrap/Button';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'

// API
import axios from "axios";
import { API_URL } from "../constants";

function Home() {
  
  const [header, setHeader] = useState([]);
  const [video, setVideo] = useState([]);
  const [wallpaper, setWallpaper] = useState([]);
  const [youtube, setYoutube] = useState([]);
  
  useEffect(() => {
    axios.get(API_URL+"home/").then(res => {
      setHeader(res.data[0].list);
      setVideo(res.data[3].list);
      setWallpaper(res.data[1].list);
      setYoutube(res.data[2].list);
    }).catch(err => {
      console.log(err)
    });
  }, []);
  
  return (
    <>
    
      <section className="container py-4">
        <Nav />
      </section>
    
      <section className="py-4 mainHeader">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-10">
              <h1 className="display-2">Hello.<br/>I&apos;m <span className="text-primary">8-Bit Studios</span>, an animation enthusiast and <a rel="noreferrer" href="https://www.youtube.com/c/8BitStudiosAnimations" target="_blank" className="text-danger">YouTuber</a>.</h1>
            </div>
          </div>
        </div>
      </section>
              
      <div className="container px-4 py-4" id="custom-cards">

        {header && header.length? (
          <PostList items={header}/>
          ):
          <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5 subHeader">
            <Skeleton height={200} count={1} />
            <Skeleton height={200} count={1} />
            <Skeleton height={200} count={1} />
          </div>
        }
      </div>
    
      <section id="hero" className="jumbotron withImage" style={{backgroundImage: `url('../images/banner3.jpg')`}}>
        <div className="container text-white py-5">

          <h1 className="display-4">Blender Animations</h1>

        </div>
      </section>
    
      <div className="container px-4 py-4" id="custom-cards">

        {video && video.length? (
          <PostList items={video}/>
          ):
          <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5 subHeader">
            <Skeleton height={200} count={1} />
            <Skeleton height={200} count={1} />
            <Skeleton height={200} count={1} />
          </div>
        }
      </div>
        
      <section id="hero" className="jumbotron withImage" style={{backgroundImage: `url('../images/banner1.jpg')`}}>
        <div className="container text-white py-5">
          <h1 className="display-4">Wallpapers</h1>
        </div>
      </section>
    
      <div className="container px-4 py-4" id="custom-cards">

        {wallpaper && wallpaper.length? (
          <PostList items={wallpaper}/>
          ):
          <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5 subHeader">
            <Skeleton height={200} count={1} />
            <Skeleton height={200} count={1} />
            <Skeleton height={200} count={1} />
          </div>
        }
      </div>
        
      <section id="hero" className="jumbotron withImage" style={{backgroundImage: `url('../images/banner2.jpg')`}}>
        <div className="container text-white py-5">

          <h1 className="display-4">YouTube</h1>

        </div>
      </section>
    
      <div className="container px-4 py-4" id="custom-cards">

        {youtube && youtube.length? (
          <PostList items={youtube}/>
          ):
          <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5 subHeader">
            <Skeleton height={200} count={1} />
            <Skeleton height={200} count={1} />
            <Skeleton height={200} count={1} />
          </div>
        }
      </div>

      <Footer />
    </>
  );
}

export default Home;