import Footer from "../components/Footer";
import Nav from "../components/Nav";
import PostList from "../views/postList";

function PageAll() {
    
  return (
    <>
    
    <section className="container py-4">
      <Nav />
    </section>
    
    <div className="container px-4 py-4" id="custom-cards">
      <PostList/>
    </div>
    
    <Footer />
    </>
  );
}

export default PageAll;