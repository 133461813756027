import { useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Truncate from 'react-truncate';

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faImage, faVideo } from '@fortawesome/free-solid-svg-icons'

import axios from "axios";
import { API_URL } from "../constants";

function PostList(props) {
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [paginate, setpaginate] = useState(12);
  
  // Show filter/search if on main list page
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  
  const [q, setQ] = useState("");
  const [searchParam] = useState(["title"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  useEffect(() => {
    // If props aren't passed in
    // ie, from the home page
    // load them manually
    if(!props.items){
      setShowFilterOptions(true)
      axios.get(API_URL+"posts/").then(res => {
        setLoaded(true);
        setItems(res.data);
      }).catch(err => {
        console.log(err)
      });
    }else{
      setShowFilterOptions(false)
      setLoaded(true);
      setItems(props.items);
    }
    
  }, [props.items]);

  const data = Object.values(items);

  //const search_parameters = Object.keys(Object.assign({}, ...data));
  const filter_items = [...new Set(data.map((item) => item.type))];

  function search(items) {
    return items.filter((item) => {
      if (item.type == filterParam) {
        return searchParam.some((newItem) => {
          return (item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1);
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1);
        });
      }
      return null;
    });
  }
  
  const tagClick = (event) => {
    event.preventDefault();
    setFilterParam(event.target.value)
  }

  const load_more = (event) => {
    event.preventDefault();
    setpaginate((prevValue) => prevValue + 6);
  };

  if (!loaded) {
    return (
      <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5 subHeader">
        <Skeleton height={200} count={1} />
        <Skeleton height={200} count={1} />
        <Skeleton height={200} count={1} />
        <Skeleton height={200} count={1} />
        <Skeleton height={200} count={1} />
        <Skeleton height={200} count={1} />
      </div>
    )
  } else {
    return (
      
      <>
      
      <nav className="navbar navbar-light bg-light" style={{display: showFilterOptions ? 'flex' : 'none' }}>
        <form className="form-inline">
          <button className="btn btn-outline-primary" value="All" type="button" onClick={tagClick}>All</button>
          {filter_items.map(d => (<button className="btn btn-outline-secondary" type="button" value={d} key={d} onClick={tagClick}>{d}</button>))}
        </form>
        <form className="form-inline">
          <input 
            className="form-control mr-sm-2" 
            type="search"
            name="search-form"
            id="search-form"
            placeholder="Search for..."
            onChange={(e) => setQ(e.target.value)}
            />
        </form>
      </nav>
                
      <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5 subHeader">
        {search(data)
            .slice(0, paginate)
            .map((item) => (
                <SinglePageItem post={item} key={item.id}/>
        ))}
        
        {search(data).length == 0 && 
          <div className="col-twelve tab-full">
            <p className="lead">Can&apos;t find anything to display, try again!</p>
          </div>
        } 

        {search(data).length > 12 &&
          
          <div className="col-twelve tab-full">
            <div className="mb-1 mt-5">
              <a className="btn btn-outline-primary" onClick={load_more} href="#0">Load More</a>
            </div>
          </div>
        }  
                
      </div>
        </>
    );
  }
}

function SinglePageItem(params) {
  
  return (
  
    <div className="col">
      <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" title={params.post.title} style={{backgroundImage: `url(${params.post.image_thumbnail})`}}>
        
        <Link to={{ pathname: "/bits/"+params.post.slug}}>
        <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
          <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold"><Truncate lines={1} ellipsis={<span>...</span>}>{params.post.title}</Truncate></h3>
          <ul className="d-flex list-unstyled mt-auto">
            <li className="me-auto">
              <MediaTypeFont post={params.post}/>
            </li>
            <li className="d-flex align-items-center">
              <svg className="bi me-2" width="1em" height="1em"></svg>
              <small>{params.post.created_at}</small>
            </li>
          </ul>
        </div>
        </Link>
      </div>
    </div>
        
  )
  
}

function MediaTypeFont(post) {
  if(post.post.type === "Youtube"){
    return <FontAwesomeIcon icon={faYoutube} size="lg"/>
  }else if (post.post.type === "Wallpaper"){
    return <FontAwesomeIcon icon={faImage} size="lg"/>
  }else if (post.post.type === "Video"){
    return <FontAwesomeIcon icon={faVideo} size="lg"/>
  }else if (post.post.object_type === "Video"){
    return <FontAwesomeIcon icon={faVideo} size="lg"/>
  }else if (post.post.object_type === "Image"){
    return <FontAwesomeIcon icon={faImage} size="lg"/>
  }
  
}

export default PostList;